import Vue from 'vue'
import Vuex from 'vuex'
import getpostings from './modules/getpostings/index.js'
import getpostingsWb from './modules/getpostingsWb/index.js'
import state from './state.js'
// import { DEFAULT_MUTATION_MODAL } from './mutation-types'

Vue.use(Vuex)

export default new Vuex.Store({
  state,
  // getters,
  // mutations,
  // actions,
  modules: {
    getpostings, getpostingsWb//autocollect
  }
})
