<style scoped>
  @import 'assets/styles/custom.css';
</style>
<style lang="sass">
  @import '../node_modules/typeface-roboto/index.css'
</style>
<template>
  <component :is="layout">
  </component>
</template>
<script>
import MainLayout from '@/layouts/MainLayout'
import PrintLayout from '@/layouts/PrintLayout'
export default {
  name: 'App',
  computed: {
    layout() {
      return (this.$route.meta.layout || 'Main') + '-Layout'
    }
  },
  components: {
    MainLayout, PrintLayout
  },
  data: () => ({}),
};
</script>
