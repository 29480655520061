import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Postings from '../views/Postings.vue'
import Page404 from '../views/Page404.vue'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'Главная',
    meta: {layout: 'main'},
    component: Home
  },
  {
    path: '/test',
    name: 'Тест',
    meta: {layout: 'print'},
    //component: () => import('../views/Postings.vue')
    component: Postings
  },
  {
    path: '/posting/:day/:type', // today or tomorrow
    name: 'Отправления',
    meta: {layout: 'main'},
    component: () => import('../views/Postings.vue')
  },
  {
    path: '/print/postings/:day',
    name: 'Печатать отправления',
    meta: {layout: 'print'},
    component: () => import('../views/print/PostingsPrint.vue')
  },
  {
    path: '/wb/postings',
    name: 'WB',
    meta: {layout: 'main'},
    component: () => import('../views/wb/Postings.vue')
  },
  {
    path: '*',
    component: Page404
  },
  { path: '/', redirect: '/posting/today' }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
