export default {
  async postings (obj1, obj) {
    console.log('вызов постингс');
    let cutoff_to
    let cutoff_from
    let typePostings = ''
    const startDay = new Date((Date.now() - 1000 * 60 * 60 * 96)).toISOString().slice(0, 10)
    const currentDate = new Date()
    const nextday = new Date(Date.now() + 1000 * 60 * 60 * 24)
    const today = currentDate.toISOString().slice(0, 10)
    const tomorrow = nextday.toISOString().slice(0, 10)
    if (obj.time === 'today') {
      cutoff_from = startDay + 'T00:00:00Z'
      cutoff_to = today + 'T23:59:59Z'
    } else if (obj.time === 'tomorrow') {
      cutoff_from = tomorrow + 'T00:00:00Z'
      cutoff_to = tomorrow + 'T23:59:59Z'
    } 
    if (obj.typePostings && obj.typePostings != 'all') {
      typePostings = obj.typePostings 
    }
    const bodyJson = {
      dir: 'asc',
      filter: {
        delivery_method_id: [21996812755000],
        provider_id: [24],
        cutoff_from: cutoff_from,
        cutoff_to: cutoff_to,
        warehouse_id: [21996812755000],
        status: typePostings,
      },
      limit: 1000,
      offset: 0,
      // sort_by: 'order_created_at',
      // translit: true,
      with: {
        analytics_data: true,
        barcodes: true,
        financial_data: true
      }
    }
    this.state.preloaderShow = true
    const result = await this._vm.$server('/v3/posting/fbs/unfulfilled/list', bodyJson)
    this.state.preloaderShow = false
    let newPost = {}
    if ('result' in result) {
      if (result.result.count > 0) {
        newPost = {
          status: '1',
          countPostings: result.result.count,
          countProd: 0,
          data: []
        }
        // console.log(result.result.postings)
        result.result.postings.forEach(function (item) {
          newPost.data.push({
            posting_number: item.posting_number,
            products: (function () {
              const prods = []
              item.products.forEach(function (i, indexProd, array) {
                prods.push({
                  name: array[indexProd].name,
                  quantity: array[indexProd].quantity,
                  artikul: array[indexProd].offer_id
                })
                newPost.countProd += array[indexProd].quantity
              })
              return prods
            })()
          })
        })
      } else if (result.result.count < 1) {
        newPost = {
          status: 'error',
          message: 'Отправлений нет'
        }
      }
    } else {
      newPost = {
        status: 'error',
        message: 'Ошибка сервера. нажмите обновить или попробуйте позже'
      }
    }
    this.commit('getpostings/editStateProd', newPost)
  },
  async singlePostings () {
    const result = await this._vm.$serverTest()
    this.commit('getpostings/editSearchData', result)
    //console.log(searchInput)
  },
  async getSearch (state, word) {
    if(word.lenght < 1) {
      state.noresult = false
      return false
    }
    this.commit('getpostings/editSearchInput', word)
    const postings = this.state.getpostings.data.postings.data
    function lowerCase (input) {
      return input.toLowerCase()
    }
    function search(value) {
      return lowerCase (JSON.stringify(value)).indexOf(lowerCase(word)) > -1
    }
    const result = await postings.filter(search)
    this.commit('getpostings/editSearchData', result)
  }
}