export default {
  editIdPoisk : (state, payLoad) => {
    state.modal = Object.assign(state.modal, payLoad)
  },
  editStateProd (state, data) {
    state.data.postings = data
  },
  updateBtn (state, data) {
    state.update.action = data.action
    state.update.attr = data.attr
  },
  editSearchData (state, data) {
    console.log('Start mutations editSearch')
    if(data.length < 1) {
      state.noresult = true
    } else {state.noresult = false}
    state.searchData = data
  },
  editSearchInput (state, data) {
    state.searchInput = data
  }
}