<template>
  <v-card>
    <v-container>
      <v-row>
        <v-col cols="4" md="3">
          <v-radio-group
            class="mt-0"
            v-model="radioDay"
            @change="linkDay"
            row
          >
            <v-radio
              label="Сегодня"
              value="today"
              class="mt-2"
            >
            </v-radio>
            <v-radio
              label="Завтра"
              value="tomorrow"
              class="mt-2"
            >
            </v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="8" md="4">
          <v-select
            class="mt-2"
            :items="itemsTypePosings"
            v-model="selectTypePostings"
            @change="linkType"
            outlined
            dense
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="5">
          <div class="pa-2">Отправления с моего склада</div>
        </v-col>
        <v-col cols="12" sm="7">
          <v-text-field
            append-icon="mdi-magnify"
            :label="searchOnlyNumber?'Номер отправления полностью':'Поиск по всей таблице'"
            hide-details
            class="pt-0"
            :value="searchinput"
            @input="onSearch"
          ></v-text-field>
          <v-checkbox
            v-model="searchOnlyNumber"
            label="Искать только по номеру отправления"
            @change="onSearch"
          ></v-checkbox>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="!noresult" class="text-md-body-1 text-sm-body-2 text-caption">
      <v-row no-gutters class="pb-5">
        <v-col cols="12" sm="4">
          <BtnPrint pagePrint="PostingsPrint"/>
        </v-col>
        <v-col cols="12" sm="7"> 
          Отправлений: {{this.postings.countPostings}}  Товаров: {{this.postings.countProd}}
        </v-col>
      </v-row>
      <v-row
        class="py-2"
        style="border-top: 1px dotted gray"
        no-gutters
        v-for="item in searchData.length>0?searchData:postings.data"
        :key="item.posting_number"
      >
        <v-col sm="3" cols="12" class="pb-3 text-h6 text-sm-subtitle-1 d-flex text-left align-center">
          {{item.posting_number}}
        </v-col>
        <v-col sm="9" cols="12">
          <v-row no-gutters v-for="tov in item.products" :key="tov.index">
            <v-col cols="12" class="pb-2">
              {{tov.artikul}} - <b>{{tov.quantity}} шт.</b><br>
              {{tov.name}}
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else>Нет результатов!</v-container>
    
  </v-card>
</template>
<script>
 import BtnPrint from '@/components/BtnPrint'
 import {mapState} from 'vuex'
  export default {
    data () {
      return {
        radioDay: null,
        isMobile: false,
        searchOnlyNumber: false,
        itemsTypePosings: [
          { text: 'Все', value: 'all' },
          { text: 'Ожидают сборки', value: 'awaiting_packaging' },
          { text: 'Ожидают отгрузки', value: 'awaiting_deliver' },
          { text: 'Спорные', value: 'not_accepted' },
        ],
        selectTypePostings: 'all',
      }
    },
    computed: {
      ...mapState ({
        postings: state => state.getpostings.data.postings,
        searchData: state => state.getpostings.searchData,
        searchinput: state => state.getpostings.searchInput,
        noresult: state => state.getpostings.noresult,
      }),
      routeDay () {
        return this.$route.params.day
      },
      routeTypePostings () {
        return this.$route.params.type
      },
    },
    beforeDestroy () {
      if (typeof window === 'undefined') return
      window.removeEventListener('resize', this.onResize, { passive: true })
    },
    mounted () {
      this.selectTypePostings = this.routeTypePostings
      this.radioDay = this.routeDay
      this.onResize()
      window.addEventListener('resize', this.onResize, { passive: true })
      this.$store.dispatch('getpostings/postings', {time: this.routeDay, typePostings: this.routeTypePostings  })
    },
    methods: {
      onResize () {
        this.isMobile = window.innerWidth < 600
      },
      onSearch (e) {
        if (!this.searchOnlyNumber) {
          this.$store.dispatch('getpostings/getSearch', e)
        } else {
          this.$store.dispatch('getpostings/singlePostings', e)
        }
      },
      linkDay () {
        this.$router.push({
          name: 'Отправления',
          params: {
            day: this.radioDay,
            type: this.selectTypePostings
          }
        })
      },
      linkType () {
        this.$router.push({
          name: 'Отправления',
          params: {
            day: this.radioDay,
            type: this.selectTypePostings
          }
        })
      }
    },
    components: {
      BtnPrint
    },
    beforeRouteUpdate (to, from, next) {
    if (to.params.day === 'today' || to.params.day === 'tomorrow') {
      this.$store.dispatch('getpostings/postings', { obj: this, time: to.params.day, typePostings: to.params.type })
      console.log(to.params.day)
      console.log(to.params.type)
      //this.routeDay = to.params.day
      /* this.$store.commit('updateBtn', {
        action: 'postings',
        attr: { obj: this, time: to.params.day }
      }) */
      next()
    }
  }
}
</script>