<template>
    <v-app-bar
      dense
      app
      color="primary"
      dark
    >
      <v-tabs center-active centered v-model="selectedTabs">
        <v-tabs-slider color="white"></v-tabs-slider>
        <v-tab v-for="(item, key) in links" v-bind:key="item.index"
        style="padding:0">
          <router-link class="white--text text-decoration-none py-3" :to="item.to"
          style="padding: 0 10px">
            {{key}}
          </router-link>
        </v-tab>

      </v-tabs>
      <v-spacer></v-spacer>
      <div class="mt-5" style="white-space: nowrap;">
      <v-switch
        color="white"
        v-model="$vuetify.theme.dark"
        @change="setDark($vuetify.theme.dark)"
        label="Dark"
        flat
      ></v-switch>
      </div>
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        bottom
        color="white"
      ></v-progress-linear>
      <iframe id="printFrame">
      </iframe>
    </v-app-bar>
</template>
<script>
import VueCookies from 'vue-cookies'

export default {
  computed: {
    loading () {
      return this.$store.state.preloaderShow
    },
    keyTab () {
      return this.$route.name || 'Главная'
    }
  },
  data: () => ({
    dark: 1,
    links: 
    {
      'Главная':
        {
          to: '/',
          key: 0,
      },
      'Отправления':
        {
          to: '/posting/today/all',
          key: 1,
      },
      'WB':
        {
          to: '/wb/postings',
          key: 2
        }
    },
    selectedTabs: null
  }),
   mounted() {
     this.$vuetify.theme.dark = VueCookies.get('dark')
     this.selectedTabs = this.links[this.keyTab].key
   },
   methods: {
     setDark (on) {
       if ( on > 0 ) {
         VueCookies.set('dark', on, 'infinity')
       } else {
         VueCookies.remove('dark')
       }
     }
   }
};
</script>