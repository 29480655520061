<template>
  <div>
    <div id="pagePrintPostings" style="margin: 7px">
      <table :style="tableStyle">
        <tr>
          <td colspan="3">
            Отправлений - {{postings.countPostings}}, товаров - {{postings.countProd}}
          </td>
        </tr>
        <tr :style="theadStyle">
          <td :style="[tdStyle, firstTdStyle]">Отправление</td>
          <td :style="[tdStyle, nameStyle]">Состав</td>
          <td :style="tdEndStyle">шт</td>
        </tr>
      </table>
      <table :style="tableStyle" class="break" v-for="item in postings.data" :key="item.index">
        <tr v-for="( tov, index ) in item.products" 
          :key="tov.posting_number"
          :style="trStyle"
        >
          <td
            v-if="index < 1" 
            :rowspan="index < 1 ? item.products.length : 1"
            :style="[tdStyle, firstTdStyle]"
            >
            {{item.posting_number}}
          </td>
          <td :style="[tdStyle, nameStyle]">{{tov.name}}</td>
            <td
              :style="[ tov.quantity > 1?boldStyle:'', tdEndStyle ]"
            >
              {{tov.quantity}}
            </td>
        </tr>
      </table>  
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
export default {
  computed: {
    ...mapState ({
      postings: state => state.getpostings.data.postings
    })
  },
  data() {
    return {
      tableStyle: {
        fontSize: '10px',
        borderSpacing: 0,
        borderCollapse: 'collapse',
        '-webkit-print-color-adjust': 'exact',
      },
      tdStyle: {
        padding: '2px 2px',
        border: '1px solid gray',
      },
      nameStyle: {
        width: '680px'
      },
      tdEndStyle: {
        padding: '0 2px',
        width: '25px',
        border: '1px solid gray'
      },
      boldStyle: {
        fontWeight: 'bold',
        backgroundColor: 'rgb(135, 138, 141)'
      },
      firstTdStyle: {
        whiteSpace: 'nowrap',
        width: '120px'
      },
      divStyle: {
        padding: '0 1px',
        lineHeight: '30px'
      },
      trStyle: {
      },
      theadStyle: {
        fontWeight: 'bold'
      }
    }
  },
};
</script>