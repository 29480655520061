<template>
  <div>
    <v-btn
      :loading="printShow"
      class="ml-5"
      color="primary"
      elevation="1"
      small
      @click="pdfShowF"
      >PDF</v-btn
    >
    <v-btn
      :loading="printShow"
      class="ml-5"
      color="primary"
      elevation="1"
      small
      @click="printShowF('id1')"
      >WB-Чем</v-btn
    >
    <v-btn
      :loading="printShow"
      class="ml-5"
      color="primary"
      elevation="1"
      small
      @click="printShowF('id2')"
      >WB-Наб</v-btn
    >
    <v-btn
      :loading="printShow"
      class="ml-5"
      color="primary"
      elevation="1"
      small
      @click="printShowF('id3')"
      >WB-Креп52</v-btn
    >
    <v-textarea
      solo
      name="stikers"
      label="Введите номера заданий через запятую (555,8758)"
      v-model="stikers"
    ></v-textarea>
    <component
      style="display: none"
      id="printContent"
      :is="this.pagePrint"
      @hook:mounted="toPage"
      v-if="printShow"
    >
    </component>
  </div>
</template>
<script>
import PostingsPrint from "../views/print/PostingsPrint.vue";
export default {
  props: ["pagePrint"],
  data: () => ({
    printShow: false,
    pdfShow: false,
    report: "pdf",
    stikers: "",
    idWb: ""
  }),
  methods: {
    printShowF(id) {
      this.idWb = id
      this.report = "print";
      this.printShow = true;
    },
    pdfShowF() {
      this.report = "pdf";
      this.printShow = true;
    },
    printRemove() {
      this.printShow = false;
      this.report = "pdf";
      this.stikers = ''
    },
    toPage() {
      if (this.report === "pdf") {
        this.test('pdf',"pdf/getPdf");
      } else if (this.report === "print") {
        this.test('wb', "wb/getstikers?id="+this.idWb);
      }
    },
    async test(wb, path) {
      console.log("start test");
      var con;
      if (wb == 'wb') {
        con = this.stikers;
      } else if (wb == 'pdf') {
        con = document.getElementById("printContent").innerHTML;
      }

      var component = this;
      const url = 'https://xn--52-9kc3den.xn--p1ai/'+ path

      //const url = "http://127.0.0.1:3000/" + path;
      // const url = 'https://pdf.servisrf.ru/'
      //console.log(con.innerHTML);
      const res = await fetch(url, {
        method: "post",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify({ html: con }),
      });
      
      if (res.ok) {
        
        if (res.headers.get("Content-Type") != "application/pdf") {
          return console.log(res.json());
        }
        const myBlob = await res.blob();
        var link = document.createElement("a");
        var objectURL = URL.createObjectURL(myBlob);
        link.href = objectURL;
        link.download = "postings.pdf";
        link.click();
        objectURL = URL.revokeObjectURL(myBlob);
      } else {
        console.log("ошибка");
      }
      component.printRemove();
    },
  },
  components: {
    PostingsPrint,
  },
};
</script>