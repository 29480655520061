export default {
  searchData:[],
  searchInput: '',
  noresult: false,
  searchOnlyNumber: false,
  data: {
    postings: {
      status: 'error',
      message: 'обновление....',
      countPostings: null,
      countProd: null,
      data: []
    }
  }
}