<template>
  <div>
    <div id="print-content">
    </div>
      <iframe id="StyleIframe" srcdoc="<div id='pagePrintPostings'><table><tr><td>gfg</td></tr></table></div>"  frameborder="0"></iframe>
    <a @click="CallPrint();" title="Распечатать проект">Распечатать</a>
  </div>
</template>
<style scoped>
  #StyleIframe table td{
    background-color: red !important;
  }
</style>
<script>
  // import PostingPrint from '../views/print/PostingsPrint.vue'
  // import StyleIframe from '../assets/styles/custom.css'

  export default {
    name: 'Home',
    data: () => ({
      print: false
    }),
    components: {
    },
    methods: {
    }
  }
</script>
