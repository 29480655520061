this.install = function (Vue) {
   Vue.prototype.$server = async function (functionUrl, bodyJson) {
     console.log('start server...')
      const url = encodeURI('https://api-seller.ozon.ru' + functionUrl)
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Client-Id': '164717',
            //'Client-Id': '270916', // Новый
            'Api-Key': '19018929-573b-48cb-99fa-1713f6ab1b86',
            //'Api-Key': '8a9fe4ef-b182-4957-ab7c-97c2c5900e0c', //Новый
            'Content-Type': 'application/json;charset=utf-8'
          },
          body: JSON.stringify(bodyJson)
        })
        const commits = response.json()
        // console.log(commits)
        return commits
      } catch (e) {
        console.log('Ошибка ' + e.name + ':' + e.message + '\n' + e.stack)
        console.log('Ошибка сервера!')
    }
  },
  Vue.prototype.$serverWb = async function (/*functionUrl, bodyJson*/) {
    console.log('start server Wb...')
     // const url = encodeURI('https://suppliers-api.wildberries.ru' + functionUrl)
     // const url = 'https://suppliers-api.wildberries.ru/api/v2/orders?date_start=2022-07-03T17%3A14%3A52Z&date_end=2022-07-06T17%3A14%3A52Z&status=2&take=20&skip=0'
     try {
       /*const response = await fetch(url, {
         headers: new Headers({
          'Content-Type': '*',
          'Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2Nlc3NJRCI6IjM5MWE5ZTdmLWFlYWItNDZkMy1iMmUzLWNlMjM0ZGMwMmQ2MCJ9.EeFZVXp6pPLAV8YXa__O3i38E52WEE_jHGdSzHFC5wM',
          'Accept': '*',
          'Cache-Control': 'no-cache',
          'Connection': 'keep-alive',
          'host': 'suppliers-api.wildberries.ru',
          'credentials': 'same-origin'
          }),
         //body: JSON.stringify(bodyJson)
       })
       const commits = response.text()
       console.log(commits)
       return commits*/

     } catch (e) {
       console.log('Ошибка ' + e.name + ':' + e.message + '\n' + e.stack)
       console.log('Ошибка сервера!')
   }
 },
  Vue.prototype.$serverTest = async function () {
    return [
      {posting_number: 1111111111,
        products: [
          { name: 'Самолёт',
            quantity: 5,
            offer_id: 999666
          },
          { name: 'Танк',
            quantity: 5,
            offer_id: 9996665
          }
        ]
      },
      {posting_number: 222222,
        products: [
          { name: 'Самолёт',
            quantity: 5,
            offer_id: 999666
          },
          { name: 'Танк',
            quantity: 5,
            offer_id: 9996665
          }
        ]
      }
    ]
    
  }
}
